



















































































































































































































































































import {
  computed,
  defineComponent,
  reactive,
  Ref,
  ref,
  set,
  onMounted
} from '@vue/composition-api';
import axios from 'axios';
import _, { forEach } from 'lodash';
import { useUserGetters, useDbGetters, useUserActions } from '@/store';
import imageIds from '@/generated/pilotcity_images';

export default {
  setup(props: any, context: any) {
    const { collection } = useDbGetters(['collection']);
    const { getObjectId } = useUserGetters(['getObjectId']);
    const { getAllStudents } = useUserActions(['getAllStudents']);
    const { fetchStudentById } = useUserActions(['fetchStudentById']);
    const { getUser } = useUserGetters(['getUser']);
    const user: any = computed({
      get: () => getUser.value,
      set: newVal => {
        context.emit('input', newVal);
      }
    });
    const cloneModal = ref(false);
    const programName = ref('');
    const currentProgram: any = ref('');
    if (localStorage.getItem('program')) {
      const params: any = localStorage.getItem('program');
      window.open(`/program/${params}/0`, '_self');
    }
    // const observer: any = ref('');
    const isVerified = ref(false);
    const role = computed(() => {
      const types = user.value?.userTypes;
      if (types?.includes('School') || types?.includes('Parent') || types?.includes('Teacher'))
        return 'Monitor';
      if (types?.includes('Employer')) return 'Manage';
      return 'none';
    });
    const studentsLength: any = ref(0);
    const organizerAndStudentPrograms: Ref<Record<string, any>> = ref([]);
    const studentPrograms: Record<string, any> = reactive([]);
    const images: Ref<Array<string>> = ref([]);

    // Function to cancel sponsor requests
    async function cancelSponsorRequest(programIndex) {
      // Set loading property to document
      set(studentPrograms, programIndex, {
        ...studentPrograms[programIndex],
        cancelSponsorRequestLoading: true
      });
      await collection.value!('SponsorRequest').updateOne(
        {
          _id: studentPrograms[programIndex].sponsorRequestId,
          participant_id: getObjectId.value
        },
        {
          $set: {
            status: 'canceled'
          }
        }
      );
      // Remove document from displayed array of programs
      studentPrograms.splice(programIndex, 1);
    }

    const handleClickOnProgram = (isProgramPublished, programId) => {
      if (isProgramPublished) {
        context.root.$router.push({
          path: `/monitor?program=${programId.toString()}`
        });
      } else {
        context.root.$router.push({ path: `/guide/${programId}/0` });
      }
    };

    // clone functionality
    function openCloneModal(val) {
      currentProgram.value = val;
      cloneModal.value = true;
    }

    // const isCloningProgram = ref(false);
    const isVerifying = ref(false);
    const isCloning = ref(false);

    async function cloneProgram() {
      isCloning.value = true;
      const API_ENDPOINT = process.env.VUE_APP_CLONE_PROGRAM;

      const data = {
        owner_id: user.value?._id.toString(),
        program_id: currentProgram.value._id.toString()
      };
      try {
        // isCloningProgram.value = true;
        const resp = await axios.post(`${API_ENDPOINT}/clone-program`, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (resp.data) {
          isCloning.value = false;
          cloneModal.value = false;
          // isCloningProgram.value = false;
          context.root.$router.push({
            path: `/guide/${resp.data.data._id.toString()}/0`
          });
        }
      } catch (err) {
        console.log(err);
        // isCloningProgram.value = false;
        isCloning.value = false;
        cloneModal.value = false;
      }
    }

    async function routeToLatestAdk(doc) {
      let userType;
      if (doc.organizers?.find(org => org?.userId?.toString() === getObjectId.value?.toString())) {
        userType = 'organizer';
      } else {
        userType = 'participant';
      }
      let document;
      if (userType === 'participant') {
        document = await fetchStudentById({
          user_id: getObjectId.value?.toString(),
          program_id: doc._id.toString()
        });
      } else {
        document = doc;
      }
      let index = 0;
      if (document.adks.length > 0) {
        for (let k = document.adks.length - 1; k >= 0; k--) {
          if (document.adks[k].status && document.adks[k].completed) {
            index = document.adks.indexOf(document.adks[k]);
            break;
          }
        }
      }
      context.root.$router.push({
        name: 'guide',
        params: {
          programId: doc._id.toString(),
          page: `${index}`,
          userType
        }
      });
    }

    const skip = ref(0);
    const limit = ref(10);
    const allProgramsFetched = ref(false);
    const isFetching = ref(false);

    function getAllPrograms() {
      const API_ENDPOINT = process.env.VUE_APP_PROGRAM_LIST;
      isFetching.value = true;
      axios
        .get(`${API_ENDPOINT}?skip=${skip.value}&limit=${limit.value}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        })
        .then(res => {
          if (res.data?.data?.length) {
            res.data.data.forEach(element => {
              organizerAndStudentPrograms.value.push(element);
            });
            isFetching.value = false;
          } else {
            allProgramsFetched.value = true;
            isFetching.value = false;
          }
          /** fetch images with program */
          let n = studentPrograms.length + organizerAndStudentPrograms.value.length;
          images.value = new Array(n);
          let len = imageIds.length;
          const taken = new Array(n);
          while (n--) {
            const x = Math.floor(Math.random() * len);
            images.value[n] = imageIds[x in taken ? taken[x] : x];
            taken[x] = --len in taken ? taken[len] : len;
          }
        })
        .catch(error => {
          console.log(error);
          isFetching.value = false;
        });
    }

    function isEmployeer(program) {
      if (
        program?.organizers.length >= 0 &&
        program?.organizers?.find(org => org?.userId?.toString() === getObjectId.value?.toString())
      ) {
        return true;
      }
      return false;
    }

    function isCloneFetureShow(program) {
      if (
        program.organizers.length >= 0 &&
        program.organizers?.find(
          org => org?.userId?.toString() === getObjectId.value?.toString()
        ) &&
        program.published
      ) {
        return true;
      }
      return false;
    }

    async function loadMorePrograms() {
      skip.value += 10;
      await getAllPrograms();
    }

    onMounted(async () => {
      getAllPrograms();
      studentsLength.value = await getAllStudents();
    });

    const redirectToDetailsPage = url => {
      if (url) {
        const sharableProgramName = url.split('/')[2];
        context.root.$router.push({
          name: 'Details',
          params: {
            name: sharableProgramName,
            schemaType: 'program',
            isTemplate: false
          }
        });
      }
    };

    return {
      role,
      getAllPrograms,
      loadMorePrograms,
      cloneModal,
      isVerified,
      isVerifying,
      cloneProgram,
      currentProgram,
      openCloneModal,
      programName,
      organizerAndStudentPrograms,
      studentPrograms,
      cancelSponsorRequest,
      images,
      studentsLength,
      handleClickOnProgram,
      routeToLatestAdk,
      isEmployeer,
      isCloning,
      isCloneFetureShow,
      isFetching,
      allProgramsFetched,
      redirectToDetailsPage
    };
  }
};
